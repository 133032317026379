$default: #007bff !default;
$default_dark: #0562c7 !default;

$success: #56c17f !default;
$success_dark: #56c17f !default;

$danger: #ff7763 !default;
$danger_dark: #ff7763 !default;

$info: #17a2b8 !default;
$info_dark: #138b9e !default;

$warning: #eab000 !default;
$warning_dark: #ce9c09 !default;

$awesome: #685dc3 !default;
$awesome_dark: #4c3fb1 !default;

$default_timer: #007bff !default;
$default_timer_filler: #fff !default;

$success_timer: #28a745 !default;
$success_timer_filler: #fff !default;

$danger_timer: #dc3545 !default;
$danger_timer_filler: #fff !default;

$info_timer: #17a2b8 !default;
$info_timer_filler: #fff !default;

$warning_timer: #eab000 !default;
$warning_timer_filler: #fff !default;

$awesome_timer: #685dc3 !default;
$awesome_timer_filler: #fff !default;
