@import "_variables.scss";

.notification__item--default {
  background-color: $default;
  border-left: 8px solid $default_dark;

  .notification__timer {
    background-color: $default_timer;
  }
  .notification__timer-filler {
    background-color: $default_timer_filler;
  }
  .notification__close {
    background-color: $default;
  }
}

.notification__item--success {
  background-color: $success;
  border-left: 8px solid $success_dark;

  .notification__timer {
    background-color: $success_timer;
  }
  .notification__timer-filler {
    background-color: $success_timer_filler;
  }
  .notification__close {
    background-color: $success;
  }
}

.notification__item--danger {
  background-color: $danger;
  border-left: 8px solid $danger_dark;

  .notification__timer {
    background-color: $danger_timer;
  }
  .notification__timer-filler {
    background-color: $danger_timer_filler;
  }
  .notification__close {
    background-color: $danger;
  }
}

.notification__item--info {
  background-color: $info;
  border-left: 8px solid $info_dark;

  .notification__timer {
    background-color: $info_timer;
  }
  .notification__timer-filler {
    background-color: $info_timer_filler;
  }
  .notification__close {
    background-color: $info;
  }
}

.notification__item--warning {
  background-color: $warning;
  border-left: 8px solid $warning_dark;

  .notification__timer {
    background-color: $warning_timer;
  }
  .notification__timer-filler {
    background-color: $warning_timer_filler;
  }
  .notification__close {
    background-color: $warning;
  }
}

.notification__item--awesome {
  background-color: $awesome;
  border-left: 8px solid $awesome_dark;

  .notification__timer {
    background-color: $awesome_timer;
  }
  .notification__timer-filler {
    background-color: $awesome_timer_filler;
  }
  .notification__close {
    background-color: $awesome;
  }
}