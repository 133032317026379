.notification-container--top-center,
.notification-container--top-left,
.notification-container--top-right,
.notification-container--bottom-center,
.notification-container--bottom-left,
.notification-container--bottom-right,
.notification-container--center,
.notification-container--top-full,
.notification-container--bottom-full {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
}

.notification-container--center,
.notification-container--top-center,
.notification-container--bottom-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
}

.notification-container--center,
.notification-container--top-center,
.notification-container--bottom-center {
  max-width: 350px;
}

.notification-container--center {
  top: 20px;
  height: 100%;
  pointer-events: none;
}

.notification-container--top-full,
.notification-container--bottom-full {
  width: 100%;
  min-width: 100%;
}

.notification-container--bottom-full {
  bottom: 0;
}

.flex-center {
  min-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
}

.notification-container--top-center {
  top: 20px;
}
.notification-container--bottom-center {
  bottom: 20px;
}

.notification-container--top-left {
  left: 20px;
  top: 20px;
}

.notification-container--top-right {
  right: 20px;
  top: 20px;
}

.notification-container--bottom-left {
  left: 20px;
  bottom: 20px;
}

.notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}

.notification-container--mobile-top,
.notification-container--mobile-bottom {
  pointer-events: all;
  position: absolute;
}

.notification-container--mobile-top {
  right: 20px;
  left: 20px;
  top: 20px;
}

.notification-container--mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}
